import {Component, OnInit} from '@angular/core';
import {IndexService} from '../../app-services/index.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})


export class MainComponent implements OnInit {

  pdfSrc = '/assets/download/Katalog/Katalog_04.06.2021.pdf';

  page = 1;
  totalPages: number;
  isLoaded = false;

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }

  constructor(public readonly indexService: IndexService) {


  }

  PlanerCards = this.indexService.Planer_Card;

  Cards = this.indexService.FirstCardRow;
  CardsKonzepte = this.indexService.SecondCardRow;

  ngOnInit() {


  }


}




