import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.loadScript('https://consent.cookiebot.com/ba64bd18-5fb8-4168-86b9-d68dabfe2cd6/cd.js');
  }

  public loadScript(url: string) {
    const body = document.body as HTMLDivElement;
    const cookieBot = document.getElementById('cookieBot');
    const script = document.createElement('script');
    script.innerHTML = 'text';
    script.src = url;
    script.async = true;
    // body.appendChild(script);
    cookieBot.append(script);
  }

}
