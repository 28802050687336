import {Injectable} from '@angular/core';
import {Cards, CustomerModal, Planer, PlanerModal} from '../app-models/models';


@Injectable()
export class IndexService {

  FirstCardRow: Cards[] = [
    new Cards({
      title: 'Produkte',
      img_url: 'assets/Pictures/index/News/Card_1.png',
      desc: 'Moderne Kassentischsysteme, mit oder ohne Förderband, bis hin zu hochwertigen Design-Kassenmöbeln.',
      button: 'Weiter',
      link: '/produkte',
    }),
    new Cards({
      title: 'Service Center',
      img_url: 'assets/Pictures/index/News/Card_2.png',
      desc: 'Stellen Sie Ersatzteil-, Zubehör- und Kundendienstanfragen zentral über den Service-Bereich.',
      button: 'Weiter',
      link: '/service'
    }),

    new Cards({
      title: 'Unternehmen',
      img_url: 'assets/Pictures/index/News/Dehner_2021_1600x1200.png',
      desc: 'Mit unserer Manufaktur entscheiden Sie sich für einem professionellen Partner mit mehr als 60jähriger Erfahrung.',
      button: 'Weiter',
      link: '/UeberUns'
    }),
  ];

  SecondCardRow: Cards[] = [
    new Cards({
      title: 'Projektplanung',
      img_url: 'fad fa-ruler fa-8x potrafke-color',
      desc: 'Von der technischen Konzeption bis hin zu Aufstellplänen; wir begleiten jeden Schritt mit Ihnen.',
    }),
    new Cards({
      title: 'Materialien',
      img_url: 'fad fa-tree-alt fa-8x potrafke-color',
      desc: 'Wir bieten eine vielzahl von Holz-Dekore an, die neben Belastbarkeit und Langlebigkeit auch ' +
        'ökologischen Aspekten gerecht werden.',
    }),
    new Cards({
      title: 'Engineering',
      img_url: 'fad fa-user-hard-hat fa-8x potrafke-color',
      desc: 'Durch unsere eigene Entwicklung & Konstruktion setzen wir Ihre Anforderungen und Wünsche direkt ' +
        'um mit: 3-D-CAD/CAM und 3-D-Drucktechnik.',
    }),
    new Cards({
      title: 'IT-Technik',
      img_url: 'fad fa-desktop fa-8x potrafke-color',
      desc: 'Wir planen und realisieren die Vorbereitung zur Integration und Nachrüstung Ihrer Cash-Systeme.',
    }),
    new Cards({
      title: 'Maschinenpark',
      img_url: 'fad fa-cogs fa-8x potrafke-color',
      desc: 'Handwerkliches Können und ein moderner Maschinen-park sorgen für die konstante Qualität' +
        ' unserer individuellen Produkte.',
    }),
    new Cards({
      title: 'Service',
      img_url: 'fad fa-user-headset fa-8x potrafke-color',
      desc: 'Unser Serviceteam steht Ihnen bei Fragen zu unseren Produkten stets zur Seite und unterstützt' +
        ' Sie aktiv bei Problemen.',
    }),
  ]

  Planer_Card: Planer [] = [

    new Planer(
      'Architekten',
      '/assets/Pictures/index/Planer/AdobeStock_183331864_Preview.jpeg',
      'Wir setzen Ihre Ideen und Konzept um!',
      '#modal-center-architekten'),
    new Planer(
      'Projektplaner',
      '/assets/Pictures/index/Planer/Unbenannt_1.jpg',
      'Unterstützung bei der Umsetzung auf der Baustelle!',
      '#modal-center-projektplaner'),
    new Planer(
      'Ladenbauer',
      '/assets/Pictures/index/Planer/Ladenbauer500x500.jpeg',
      'Bei Investionsentscheidung für die Zukunft sind wir der richtige Ansprechpartener für Sie!',
      '#modal-center-ladenbauer'),
    new Planer(
      'Selbständige',
      '/assets/Pictures/index/Planer/Selbstaendige_500x500p.jpg',
      'Bei Investionsentscheidung für die Zukunft sind wir der richtige Ansprechpartener für Sie!',
      '#modal-center-selbstaendige')
  ]

  Planer_Modal: PlanerModal [] = [

    new PlanerModal('/assets/Pictures/index/Planer/Architekt_planer_original.jpeg', 'Title', 'Lorem ipsum ' +
      'dolor sit amet, consetetur sadipscing elitr, ' +
      'sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos ' +
      'et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.'),
    new PlanerModal('/assets/Pictures/index/Planer/Projekt_planer_original.jpeg', 'Titel', 'gwevevewg ewvewvwevwew')

  ]
  CustomerModal: CustomerModal [] = [
    new CustomerModal('Architekten', 'test message', 'assets/Pictures/index/Planer/Architekt_planer_original.jpeg')
  ]


}


