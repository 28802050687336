import { Injectable } from "@angular/core";
export class IMAGES {

    constructor(public image_src: string, public image_alt: string, public image_caption: string) {

    }
}


@Injectable()
export class ImageProdukteService {

    // BR 72/73 Einzelkassentische

    image_nonfood_7273: IMAGES[] = [

        new IMAGES('../../../../assets/Pictures/Produkte/NON-Food/BR72-73/Einzelkassentisch/1.jpg', 'Potrafke Kassentisch 72/73', 'BR72/73 Bild 1'),
        new IMAGES('../../../../assets/Pictures/Produkte/NON-Food/BR72-73/Einzelkassentisch/2.jpg', 'Potrafke Kassentisch 72/73', 'BR72/73 Bild 2')
    ];


    // BR 84/85 Einzelkassentische

    image_nonfood_8485: IMAGES[] = [

        new IMAGES('../../../../assets/Pictures/Produkte/NON-Food/BR84-85/Einzelkassentisch/1.jpg', 'Potrafke Kassentisch 84/85', 'BR84/85 Bild 1'),
        new IMAGES('../../../../assets/Pictures/Produkte/NON-Food/BR84-85/Einzelkassentisch/2.jpg', 'Potrafke Kassentisch 84/85', 'BR84/85 Bild 2'),
        new IMAGES('../../../../assets/Pictures/Produkte/NON-Food/BR84-85/Einzelkassentisch/3.jpg', 'Potrafke Kassentisch 84/85', 'BR84/85 Bild 3'),
        new IMAGES('../../../../assets/Pictures/Produkte/NON-Food/BR84-85/Einzelkassentisch/4.jpg', 'Potrafke Kassentisch 84/85', 'BR84/85 Bild 4')
    ];
}
