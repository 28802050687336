import { Component, OnInit } from '@angular/core';
declare var UIkit: any;
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  Modalclose(){

    var modal = UIkit.modal("#uv", {center: true});
    modal.hide();
  }

  constructor() { }

  ngOnInit() {


  }

}
