import { Component, OnInit } from '@angular/core';
import {ZubehoerService} from "../zubehoer.service";

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {

  constructor(public zubehoerService: ZubehoerService) {

  }
  overviewZubehoer = this.zubehoerService.zubehoerOverview;
  ngOnInit(): void {
  }

}
