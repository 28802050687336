import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SlideshowComponent} from '../index/Section_1/slideshow.component';
import {ContactComponent} from '../contact/contact.component';
import {PartnerComponent} from '../partner/partner.component';
import {UeberUnsComponent} from '../ueber-uns/ueber-uns.component';
import {NeuheitenComponent} from '../produkte/neuheiten/neuheiten.component';
import {EinzelhandelComponent} from '../produkte/kategorien/einzelhandel/einzelhandel.component';
import {DiscounterComponent} from '../produkte/kategorien/discounter/discounter.component';
import {CashcarryComponent} from '../produkte/kategorien/cashcarry/cashcarry.component';
import {NonfoodComponent} from '../produkte/kategorien/nonfood/nonfood.component';
import {SonderkassenComponent} from '../produkte/kategorien/sonderkassen/sonderkassen.component';
import {LadenbauelementeComponent} from '../produkte/kategorien/Ladenbauelemente/Ladenbauelemente.component';
import {DesignComponent} from '../produkte/eigenschaften/design/design.component';
import {MediaComponent} from '../media/media.component';
import {ZubehoerComponent} from '../zubehoer/zubehoer.component';
import {OverviewComponent} from '../zubehoer/overview/overview.component';
import {StuehleComponent} from '../zubehoer/stuehle/stuehle.component';
import {SicherheitComponent} from '../zubehoer/sicherheit/sicherheit.component';
import {AcrylComponent} from '../zubehoer/acryl/acryl.component';
import {AcrylallComponent} from '../zubehoer/acryl/acrylall/acrylall.component';
import {AcrylfixedComponent} from '../zubehoer/acryl/acrylfixed/acrylfixed.component';
import {AcrylstandsComponent} from '../zubehoer/acryl/acrylstands/acrylstands.component';
import {AcrylwinkelComponent} from '../zubehoer/acryl/acrylwinkel/acrylwinkel.component';
import {GartencenterComponent} from '../produkte/description/gartencenter/gartencenter.component';
import {BiomaerkteComponent} from '../produkte/description/biomaerkte/biomaerkte.component';
import {ServiceComponent} from '../service/service.component';
import {ProdukteComponent} from '../produkte/produkte.component';
import {ProduktdetailsComponent} from '../produkte/produktdetails/produktdetails.component';
import {TechnikComponent} from '../produkte/technik/technik.component';

const routes: Routes = [

  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {path: 'home', component: SlideshowComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'partner', component: PartnerComponent},
  {path: 'UeberUns', component: UeberUnsComponent},
  {path: 'neuheiten', component: NeuheitenComponent},
  {path: 'einzelhandel', component: EinzelhandelComponent},
  {path: 'discounter', component: DiscounterComponent},
  {path: 'cashcarry', component: CashcarryComponent},
  {path: 'nonfood', component: NonfoodComponent},
  {path: 'sonderkassen', component: SonderkassenComponent},
  {path: 'Ladenbauelemente', component: LadenbauelementeComponent},
  {path: 'design&it', component: DesignComponent},
  {path: 'media', component: MediaComponent},
  {
    path: 'zubehoer', component: ZubehoerComponent, children: [
      {path: '', component: OverviewComponent},
      {path: 'stuehle', component: StuehleComponent},
      {path: 'sicherheit', component: SicherheitComponent},
      {
        path: 'acryl', component: AcrylComponent, children: [
          {path: '', component: AcrylallComponent},
          {path: 'acrylfixed', component: AcrylfixedComponent},
          {path: 'acrylstands', component: AcrylstandsComponent},
          {path: 'acrylwinkel', component: AcrylwinkelComponent}
        ]
      },
    ]
  },
  {path: 'gartencenter', component: GartencenterComponent},
  {path: 'biomaerkte', component: BiomaerkteComponent},
  {path: 'service', component: ServiceComponent},
  {
    path: 'produkte', component: ProdukteComponent, children: [
      {path: '', component: ProduktdetailsComponent}
    ]
  },
  {path: 'knowhow', component: TechnikComponent}


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, scrollPositionRestoration: 'enabled'}), RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RouteRoutingModule {
}
