import { Component, OnInit } from '@angular/core';



@Component({
  selector: 'app-modal-zubehoer',
  templateUrl: './modal-zubehoer.component.html',
  styleUrls: ['./modal-zubehoer.component.css']
})
export class ModalZubehoerComponent implements OnInit {

  title_stuehle = "Stühle & Fußstützen ";
  title_sicheheitstechnik = "Sicherheit";

  constructor( ) { }

  ngOnInit() {
  }




}
