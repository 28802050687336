import { Injectable } from "@angular/core";
// Product Cards

export class ProductCards {

  constructor(
    public name?: string,
    public Url?: string,
    public alt?: string,
    public routerlink?: string) {


  }
}



@Injectable()
export class ProdukteService {

  CARDS: ProductCards[] = [

    new ProductCards('Einzelhandel',
      'assets/Pictures/Produkte/Cards/Einzelhandel_optimiert.jpg',
      'Kassentisch, Einzehandel, Kassenzone',
      '/einzelhandel'),
    new ProductCards('Grüne Märkte',
      'assets/Pictures/Produkte/Cards/non-food_optimiert.jpg',
      'Kassentisch, Biomarkt, Gartencenter, Baumarkt, Tierhandlung, ',
      '/nonfood'),
    new ProductCards('Sonderkassen',
      'assets/Pictures/Produkte/Cards/duty-free_optimiert.jpg',
      'Kassentisch, Kassenzone Flughafen,Kassentisch nicht brennbar',
      '/sonderkassen'),
    // new ProductCards('Discounter',
    //   'assets/Pictures/Produkte/Cards/discounter_optimiert.jpg',
    //   'Kassentisch, Einzehandel, Kassenzone',
    //   '/discounter'),
    new ProductCards('Cash & Carry',
      'assets/Pictures/Produkte/Cards/cash-carry_optimiert.jpg',
      'Kassentisch, Einzehandel, Kassenzone',
      '/cashcarry'),
    new ProductCards('Ladenbauelemente',
      'assets/Pictures/Produkte/Cards/infotheken_optimiert.jpg',
      'Kassentisch, Einzehandel, Kassenzone',
      '/Ladenbauelemente'),
  ];

}
