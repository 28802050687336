import {AfterViewInit, Component, OnInit} from '@angular/core';
import {MediathekService} from './mediathek.service';


@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent implements OnInit {

  title = 'Mediathek';
  imgLoader: boolean;
  hidden: boolean;

  constructor(public mediathekservice: MediathekService) {


  }

  ngOnInit() {

  }

  pictures_kassentische = this.mediathekservice.images_media_kassentische;
  pictures_self_scanning = this.mediathekservice.images_media_self_scanning;
  pictures_doppelkassentische = this.mediathekservice.images_media_doppelkassentisch;
  pictures_schranksysteme = this.mediathekservice.images_media_schranksysteme;
  pictures_getraenkemaerkte = this.mediathekservice.images_media_getraenkemarkt;
  pictures_theken = this.mediathekservice.images_media_theke;


}
