import { Injectable } from "@angular/core";
class IMAGES {

    constructor(public image_alt: string, public image_url: string, public data_caption: string) {
    }
}

@Injectable()
export class ImageService {


    image_logo: IMAGES [] = [
        new IMAGES('Dehner Gartencenter', '../../../../assets/Pictures/Produkte/Gartencenter/Unternehmen_logo/Dehner-Blumenpark-Logo-JPG.jpg', 'Dehner'),
        new IMAGES('Dehner Gartencenter', '../../../../assets/Pictures/Produkte/Gartencenter/Unternehmen_logo/Sagaflor_Logo.svg.png', 'Sagaflor'),
        new IMAGES('Dehner Gartencenter', '../../../../assets/Pictures/Produkte/Gartencenter/Unternehmen_logo/bellandris.png', 'bellandris')

    ];

    image_klappe: IMAGES [] = [

        new IMAGES('Gartencenter Kassentisch', '../../../../assets/Pictures/Produkte/Gartencenter/Thumbnav_1/Thumbnav_pic1.jpg', 'Beispiel 1'),
        new IMAGES('Gartencenter Kassentisch', '../../../../assets/Pictures/Produkte/Gartencenter/Thumbnav_1/Thumbnav_pic3.jpg', 'Beispiel 3')
    ];

    image_seiteneinstieg: IMAGES [] = [

        new IMAGES('Gartencenter Kassentisch', '../../../../assets/Pictures/Produkte/Gartencenter/Thumbnav_2/Thumbnav_pic1.jpg', 'Beispiel 1'),
        new IMAGES('Gartencenter Kassentisch', '../../../../assets/Pictures/Produkte/Gartencenter/Thumbnav_1/Thumbnav_pic2.jpg', 'Beispiel 2'),
        new IMAGES('Gartencenter Kassentisch', '../../../../assets/Pictures/Produkte/Gartencenter/Thumbnav_2/Thumbnav_pic3.jpg', 'Beispiel 1'),
        new IMAGES('Gartencenter Kassentisch', '../../../../assets/Pictures/Produkte/Gartencenter/Thumbnav_1/Thumbnav_pic3.jpg', 'Beispiel 3')
    ];

    Card2_Image_1 = '../../../../assets/Pictures/Produkte/Gartencenter/Klappe_horinzontal.gif';
    Card2_Image_2 = '../../../../assets/Pictures/Produkte/Gartencenter/Klappe_vertical.gif';
    Card1_Image_3 = '../../../../assets/Pictures/Produkte/Gartencenter/Card1_Image_1.jpg';

}
