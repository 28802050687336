import { Injectable } from "@angular/core";
// Class Intro Cards

export class Cards {


  constructor(
    public title: string,
    public descr: string,
    public routerlink: string,
    public alt: string,
    public fontawesome: string) {
  }
}


// Class Stuele

export class Stuehle {

  constructor(
    public title?: string,
    public desc?: {
      material?: string,
      farbe?: string,
      belastbarkeit?: string,
      sitzmasse?: string,
      hoehe?: string,
      neigung_grad?: string,
      verstellmechanismus?: string,
      artikelnummer?: string
      material_fussplatte?: string,

    },
    public extras?: string,
    public pic_url?: string,
    public alt_img?: string,
  ) {
  }
}

// Class Fussstuetzen

export class Fussstuetzen {

  constructor(
    public title?: string,
    public desc?: {
      material?: string,
      farbe?: string,
      plattenmass?: string,
      gestellmasse?: string,
      hoehe?: string,
      neigung_grad?: string,
      verstellmechanismus?: string,
      artikelnummer?: string
      material_fussplatte?: string,
    },
    public extras?: string,
    public pic_url?: string,
    public alt_img?: string,
    public material?: string) {


  }
}


// Class Sicheheit

export class SicherheitstechnikModal {

  constructor(
    public title?: string,
    public artikelnummer?: string,
    public desc?: {
      text: string

    },
    public pic_url?: string
  ) {
  }

}

// Array Intro Cards

@Injectable()
export class ZubehoerService {


  zubehoerOverview: Cards [] = [

    new Cards(
      'Stühle & Fußstützen',
      'Arbeit darf nicht weh tun.... Ein ergonomisch ausgestatteter Arbeitsplatz \n' +
      'schützt die Mitarbeiter vor Fehlbelastungen und steigert somit ihre Produktivität. ',
      'stuehle',
      'Kassenampeln',
      'fad fa-chair-office fa-8x'),
    new Cards('Acryl-Schutz',
      'Ampeln/Anzeigen z.B. aus „Acryl“ mit LED-Beleuchtung. In Farbe und Form verschieden konfigurierbar. ' +
      'Für eine sichere und effiziente Kundenführung.',
      'acryl', 'Kassenampeln',
      'fad fa-window-frame-open fa-8x'),
    new Cards(
      'Cash Handling',
      'Standrohr und Aufnahmen die direkt auf den Kassentisch montiert werden. Peripherien wie Drucker,' +
      ' Kundenanzeigen und EC-Cash-Geräte werden daran befestigt. ',
      'link',
      'Kassenampeln',
      'fad fa-scanner-keyboard fa-8x'),
    new Cards(
      'Sicherheit',
      'Standrohr und Aufnahmen die direkt auf den Kassentisch montiert werden. Peripherien wie Drucker, ' +
      'Kundenanzeigen und EC-Cash-Geräte werden daran befestigt. ',
      'sicherheit',
      'Sicherheit',
      'fad fa-user-secret fa-8x'),
    new Cards(
      'Ampeln & Leitsysteme',
      'Zum zuverlässige Sperren sowie regeln des Kundenverkehrs.Hilft beim senken der Inventurdifferenzen.',
      'link', 'Kassensperren', 'fad fa-sign-in-alt fa-8x'),
    new Cards('Reinigungsmittel',
      'Ampeln/Anzeigen z.B. aus „Acryl“ mit LED-Beleuchtung. In Farbe und Form verschieden konfigurierbar. ' +
      'Für eine sichere und effiziente Kundenführung.',
      'link', 'Kassenampeln',
      'fad fa-spray-can fa-8x'),
  ];


  //Array  Stuehle

  zubehoerStuehle: Stuehle [] = [

    new Stuehle(
      'Stehhilfe mit Stahlfußkreuz', {
        belastbarkeit: '120 kg',
        sitzmasse: '260 x 360 (T x B)',
        material: 'Polyurethanschaum (PU1)',
        hoehe: '620-880',
        neigung_grad: '+16° bis +2°',
        verstellmechanismus: 'Gasfeder',
        farbe: 'schwarz',
        artikelnummer: '11001'
      },
      'Keine',
      'https://www.meychair.de/Shop/media/catalog/product/cache/e0f68bb78360b20441927afc0b936653/1/1/11001_1_AF0-PU1-9005-schwarz_NEW.png',
      '',
    ),
    new Stuehle(
      'Stehhilfe, zusammenklappbar', {
        belastbarkeit: '120 kg',
        sitzmasse: '210 x 370 (T x B)',
        material: 'Stoff (ST) gepolstert',
        hoehe: '620-880',
        neigung_grad: '+16° bis +2°',
        verstellmechanismus: 'Handrad',
        farbe: 'schwarz',
        artikelnummer: '11002'
      },
      'Zusammenklappbar',
      'https://www.meychair.de/Shop/media/catalog/product/cache/e0f68bb78360b20441927afc0b936653/1/1/11002_1_AF1-PU1-9005-schwarz_NEW.png',
      '',
    ),

  ];

  // FUSSSTUETZEN

  zubehoerFussstuetzen: Fussstuetzen [] = [

    // Fussstuetzen 1

    new Fussstuetzen(
      'Fußstütze, mit Fußplatte aus Vollholz, Modell EFS 90 hell',
      {
        material: 'Buche hell',
        hoehe: '130-330',
        neigung_grad: '0° bis 30°',
        plattenmass: '350 x 460 x 28 (T x B x D)',
        gestellmasse: '360 x 500 (T x B)',
        verstellmechanismus: 'Gasfeder',
        farbe: 'schwarz',
        artikelnummer: '13211'
      },
      'Vollholz',
      'https://www.meychair.de/Shop/media/catalog/product/cache/e0f68bb78360b20441927afc0b936653/m/e/meykonfig4-2_fussstuetze_standalone_efs90_buche_hell.png',
      ''
    ),

    // Fussstuetzen 2

    new Fussstuetzen(
      'Fußstütze, mit Fußplatte aus Vollholz, Modell EFS 90 dunkel',
      {
        material: 'Buche dunkel',
        hoehe: '130-330',
        neigung_grad: '0° bis 30°',
        plattenmass: '350 x 460 x 28 (T x B x D)',
        gestellmasse: '360 x 500 (T x B)',
        verstellmechanismus: 'Gasfeder',
        farbe: 'schwarz',
        artikelnummer: '13212'
      },
      'Vollholz',
      'https://www.meychair.de/Shop/media/catalog/product/cache/e0f68bb78360b20441927afc0b936653/m/e/meykonfig4-2_fussstuetze_standalone_efs90_buche_dunkel.png',
      '',
      'Vollholz Buche',
    ),

    // Fussstuetzen 3

    new Fussstuetzen(
      'Fußstütze mit Fußplatte, aus Kunststoff,',
      {
        material: 'Kunststoff',
        hoehe: '110-320',
        neigung_grad: '0° bis 30°',
        plattenmass: '320 x 420 (T x B)',
        gestellmasse: '360 x 500 (T x B)',
        verstellmechanismus: 'Gasfeder',
        farbe: 'schwarz',
        artikelnummer: '13204'
      },
      'Keine',
      'https://www.meychair.de/Shop/media/catalog/product/cache/e0f68bb78360b20441927afc0b936653/m/e/meykonfig4-2_arbeitsdrehstuehle_fussstuetzen_standalone_efs90.png',
      ''
    )
  ];

  // Sicherheitstechnik

  zubehoerSicheheitstechnik: SicherheitstechnikModal [] = [

    new SicherheitstechnikModal(
      'EMD935F',
      'XX.XXXXXXXX.XXXXXXX',
      {
        text: 'Die neue EverFocus-Reihe mobiler Kameras bietet unglaubliche HD-Bilder in einem' +
          ' kleinen Formfaktor mit robusten Design, das sie zum perfekten Einsatz in Ihrer' +
          ' Kassenzone macht. Die EMD935F verwendet ein wetterfestes 1 / 2,9-Zoll-CMOS von Sony ' +
          'mit 2,24 MP und IP68. Der Sensor bietet eine hohe Auflösung von 1080 p und 6 LEDs mit einer Reichweite ' +
          'von 10 m, um Sichtbarkeit bei schlechten Lichtverhältnissen zu gewährleisten.'
      },
      'https://www.everfocus.com/app/uploads/2016/07/EMD935F.jpg'),
    new SicherheitstechnikModal(
      'EMD935F',
      'XX.XXXXXXXX.XXXXXXX',
      {
        text: 'Die neue EverFocus-Reihe mobiler Kameras bietet unglaubliche HD-Bilder in einem' +
          ' kleinen Formfaktor mit robusten Design, das sie zum perfekten Einsatz in Ihrer' +
          ' Kassenzone macht. Die EMD935F verwendet ein wetterfestes 1 / 2,9-Zoll-CMOS von Sony ' +
          'mit 2,24 MP und IP68. Der Sensor bietet eine hohe Auflösung von 1080 p und 6 LEDs mit einer Reichweite ' +
          'von 10 m, um Sichtbarkeit bei schlechten Lichtverhältnissen zu gewährleisten.'
      },
      'https://www.everfocus.com/app/uploads/2016/07/EMD935F.jpg')
  ]
}

