import {Component, OnInit} from '@angular/core';
import {ImageService} from "../image.service";


@Component({
    selector: 'app-gartencenter',
    templateUrl: './gartencenter.component.html',
    styleUrls: ['./gartencenter.component.css']
})
export class GartencenterComponent implements OnInit {


    sendEvent = () => {
        (<any>window).ga('send', 'event', {
            eventCategory: 'eventCategory',
            eventLabel: 'eventLabel',
            eventAction: 'IndexSlider',
            eventValue: 10
        });
    }


    constructor(public imageService: ImageService) {

    }

    pictures_klappe = this.imageService.image_klappe;
    pictures_logo = this.imageService.image_logo;
    pictures_seiteneinstieg = this.imageService.image_seiteneinstieg;

    ngOnInit() {
    }

}
