import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acrylfixed',
  templateUrl: './acrylfixed.component.html',
  styleUrls: ['./acrylfixed.component.css']
})
export class AcrylfixedComponent implements OnInit {

  acrylclass :string [] = [
    'Maßgeschneiderte Lösung mit passenden Ausschnitten',
    'Scheiben werden im eigenen Haus gefertigt',
    'Hoher Schutz und ansprechendes Design',
    'Als Einzel- oder Serienlösung'
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
