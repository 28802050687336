import {Component, OnInit} from '@angular/core';
import {NewsService} from '../../app-services/news.service';

@Component({
  selector: 'app-neuheiten',
  templateUrl: './neuheiten.component.html',
  styleUrls: ['./neuheiten.component.css']
})
export class NeuheitenComponent implements OnInit {

  title = 'News 2021';

  news = this.newsService.NEWSCARDS;
  show: boolean ;

  constructor(public newsService: NewsService) {
  }

  ngOnInit() {


  }

}
