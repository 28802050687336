import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouteRoutingModule} from './route/route-routing.module'
import {AppComponent} from './app.component';
import {HeaderComponent} from './header/header.component';
import {ProdukteComponent} from './produkte/produkte.component';
import {FooterComponent} from './footer/footer.component';
import {MainComponent} from './index/main/main.component';
import {ContactComponent} from './contact/contact.component';
import {UeberUnsComponent} from './ueber-uns/ueber-uns.component';
import {HttpClientModule, HttpClientJsonpModule, HttpClient} from '@angular/common/http';
import {ModalComponent} from './index/modal/modal.component';
import {NeuheitenComponent} from './produkte/neuheiten/neuheiten.component';
import {ProduktdetailsComponent} from './produkte/produktdetails/produktdetails.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {EinzelhandelComponent} from './produkte/kategorien/einzelhandel/einzelhandel.component';
import {DiscounterComponent} from './produkte/kategorien/discounter/discounter.component';
import {PartnerComponent} from './partner/partner.component';
import {CashcarryComponent} from './produkte/kategorien/cashcarry/cashcarry.component';
import {NonfoodComponent} from './produkte/kategorien/nonfood/nonfood.component';
import {SonderkassenComponent} from './produkte/kategorien/sonderkassen/sonderkassen.component';
import {LadenbauelementeComponent} from './produkte/kategorien/Ladenbauelemente/Ladenbauelemente.component';
import {DesignComponent} from './produkte/eigenschaften/design/design.component';
import {ZubehoerComponent} from './zubehoer/zubehoer.component';
import {HashLocationStrategy, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {MediaComponent} from './media/media.component';
import {GartencenterComponent} from './produkte/description/gartencenter/gartencenter.component';
import {BiomaerkteComponent} from './produkte/description/biomaerkte/biomaerkte.component';
import {ImageService} from './produkte/description/image.service';
import {MediathekService} from './media/mediathek.service';
import {ImageProdukteService} from './produkte/kategorien/image.produkte.service';
import {MobileComponent} from './index/mobile/mobile.component';
import {ServiceComponent} from './service/service.component';
import {ProdukteService} from './produkte/produkte.service';
import {ZubehoerService} from './zubehoer/zubehoer.service';
import {ModalZubehoerComponent} from './zubehoer/modal-zubehoer/modal-zubehoer.component';
import {SlideshowComponent} from './index/Section_1/slideshow.component';
import {IndexService} from './app-services/index.service';
import {TechnikComponent} from './produkte/technik/technik.component';
import {SvgComponent} from './index/svg/svg.component';
import {StuehleComponent} from './zubehoer/stuehle/stuehle.component';
import {OverviewComponent} from './zubehoer/overview/overview.component';
import {SicherheitComponent} from './zubehoer/sicherheit/sicherheit.component';
import {AcrylComponent} from './zubehoer/acryl/acryl.component';
import {AcrylfixedComponent} from './zubehoer/acryl/acrylfixed/acrylfixed.component';
import {AcrylstandsComponent} from './zubehoer/acryl/acrylstands/acrylstands.component';
import {AcrylwinkelComponent} from './zubehoer/acryl/acrylwinkel/acrylwinkel.component';
import {AcrylallComponent} from './zubehoer/acryl/acrylall/acrylall.component';
import {NewsService} from './app-services/news.service';



export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ProdukteComponent,
    FooterComponent,
    MainComponent,
    ContactComponent,
    UeberUnsComponent,
    ModalComponent,
    NeuheitenComponent,
    ProduktdetailsComponent,
    EinzelhandelComponent,
    DiscounterComponent,
    PartnerComponent,
    CashcarryComponent,
    NonfoodComponent,
    SonderkassenComponent,
    LadenbauelementeComponent,
    DesignComponent,
    ZubehoerComponent,
    MediaComponent,
    GartencenterComponent,
    BiomaerkteComponent,
    MobileComponent,
    ServiceComponent,
    ModalZubehoerComponent,
    SlideshowComponent,
    TechnikComponent,
    SvgComponent,
    StuehleComponent,
    OverviewComponent,
    SicherheitComponent,
    AcrylComponent,
    AcrylfixedComponent,
    AcrylstandsComponent,
    AcrylwinkelComponent,
    AcrylallComponent


  ],
  imports: [
    BrowserModule,
    FormsModule,
    RouteRoutingModule,
    HttpClientModule,             // (Required) for share counts
    HttpClientJsonpModule, // (Optional) for linkedIn and tumblr share counts
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [ProdukteService, ImageService, MediathekService, ImageProdukteService, ZubehoerService, IndexService, NewsService, {
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  }, {provide: 'googleTagManagerId', useValue: 'GTM-WR2VRVZ'}],
  bootstrap: [AppComponent]
})
export class AppModule {
}
