import {Component, OnInit} from '@angular/core';
import {ProdukteService} from "../produkte.service";

@Component({
    selector: 'app-produktdetails',
    templateUrl: './produktdetails.component.html',
    styleUrls: ['./produktdetails.component.css']
})
export class ProduktdetailsComponent implements OnInit {


    constructor(public produktervice: ProdukteService) {
    }

    ngOnInit() {
    }

    Pruduktkatekorien = this.produktervice.CARDS;

}
