import {Component, OnInit} from '@angular/core';
import {ZubehoerService} from "../zubehoer.service";

@Component({
  selector: 'app-stuehle',
  templateUrl: './stuehle.component.html',
  styleUrls: ['./stuehle.component.css']
})


export class StuehleComponent implements OnInit {


  title_stuehle = "Stühle & Fußstützen ";

  constructor(public zubehoerService: ZubehoerService) {
  }

  stuehlemodal = this.zubehoerService.zubehoerStuehle;
  fussstuetzenmodal = this.zubehoerService.zubehoerFussstuetzen;

  ngOnInit(): void {
  }

}
