import {Injectable} from '@angular/core';

export class NewsCards {

  constructor(
    public img_url?: string,
    public desc?: {
      title?: string,
      article_date?: string,
      text?: string,
      text2?: string,
      additional_informations?: string,
      buttons?: boolean,
      buttonText?: string,
      routerLink?: string,
      link?: boolean,
      img_alt?: string,
      download_link?: string,
      downloadlink_desc?: string
      newslink_1?: string,
      newslink_1_desc?: string,
      newslink_2?: string,
      newslink_2_desc?: string


    }) {
  }
}

@Injectable()
export class NewsService {
  NEWSCARDS: NewsCards [] = [
    new NewsCards(
      'assets/Pictures/Produkte/Neuheiten/Banddesinfektion/Bandesinfektion.jpg',
      {
        img_alt: 'Förderbandreingung',
        title: 'Vorlaufband-Reinigung',
        article_date: '15. september 2021',
        text: 'POTRAFKE stellt Ihnen ein komplett in das Fördeband integrietes System zur kontinuierlichen Desinfektion\n' +
          'von Kassenbändern vor.\n' +
          'Die UV-Licht unterstützte Bestrahlung des Förderbandes kommt ohne den Einsatz von aggressiven und ' +
          'umweltschädlichen  Chemikalien oder Zusätzen aus. Damit ist schon nach wenigen Bandläufen eine 99,9%ige Entkeimung von nahezu allen Bakterien,' +
          ' Viren, Hefen, Schimmelpilzen und Protozoen sichergestellt.',
        download_link: 'assets/download/Informaterial/POTRAFKE_Banddesinfektion_Stand_15.04.2021.pdf',
        downloadlink_desc: 'PDF Download',
        link: true,
        buttons: false,
        routerLink: '/contact',
        buttonText: 'Kontakt',
      }),
    new NewsCards(
      'assets/Pictures/Produkte/Neuheiten/Certus_KI/Certus_KI.jpg',
      {
        title: 'Wa-Con KI, ' +
          'die Intelligente\n' +
          'Einkaufswagenkontrolle',
        article_date: '15. Mai 2021',
        text: 'Das Wa-Con KI System motiviert ihre Mitarbeiter durch\n' +
          'seine revoltionäre einfache Kontrollrountine zu mehr\n' +
          'Achtsamkeit bei nicht aufgegebener Ware! Vorbeigeführter Einkaufswagen wird\n' +
          'automatisch erfasst und als Standbild\n' +
          'auf dem Bedienermonitor angezeigt.\n' +
          'Erst nach Freigabe über einen Taster\n' +
          'kann weiter kassiert werden.\n' +
          'Die aktive Freigabe motiviert die\n' +
          'Mitarbeiter zur mehr Achtsamkeit und\n' +
          'veringert damit signifikant Ihre\n' +
          'Inventurdifferenz!',
        link: true,
        download_link: 'assets/download/Katalog/POTRAFKE_Wa-Con KI_Stand_15.04.2021.pdf',
        downloadlink_desc: 'PDF Download',
        buttons: true,
        routerLink: '/contact',
        buttonText: 'Kontakt',
      }),
    new NewsCards(
      'assets/Pictures/Produkte/Neuheiten/Sagaflor2020.jpg',
      {
        title: 'PRO garten & tier in Kassel',
        article_date: '04 August 2021',
        text: 'Die Messe richtet sich an spezielle Fachhändler aus den Bereichen Garten und Heimtier.' +
          ' Wir stellen unser neues  Abwicklungskonzepte vor, die der Besonderheiten und Bedürfnisen des grünen Sortiments Rechnung tragen. ' +
          'Zudem ist die Messe eine ausgezeichnete Gelegenheit für den Austausch mit Kollegen und Geschäftspartnern.',
        text2: 'Wir freuen uns auf viele ' +
          'interessierte Messebesucher, und auf anregende und informative Gespräche. ',
        additional_informations: '23.-24.09.2021, Halle 5, Stand 553',
        link: true,
        download_link: 'assets/download/Katalog/Gartencenter_Handout_web.pdf',
        downloadlink_desc: 'PDF Download',
        buttons: false,
        routerLink: '/contact',
        buttonText: 'Kontakt',
      }),


    // new NewsCards(
    //   'assets/Pictures/Produkte/Neuheiten/Schmutzschublade_2.jpg',
    //   {
    //     img_alt: 'Förderbandreingung',
    //     title: 'Vorlaufband-Reinigung',
    //     article_date: '06. Oktober 2020',
    //     text: 'Neuartiges System, indem Bürsten unterhalb des Förderbandes dieses von Schutz reinigen,\n' +
    //       'der in verschließbaren Schmutz-Schubladen aufgefangen wird. Einlegeböden sorgen für eine\n' +
    //       'komfortable Reinigungs-Routine.',
    //     downloadlink: 'assets/download/Infomaterial/Abstandsmatten.pdf',
    //     downloadlink_desc: 'Informationsmaterial downloden',
    //     link: false,
    //     buttons: true,
    //     routerLink: '/contact',
    //     buttonText: 'Kontakt'
    //   }),
    //
    // new NewsCards(
    //   'assets/Pictures/Produkte/Neuheiten/Corona/Infektionsschutz_Volleinhausung.jpg',
    //   {
    //     img_alt: 'Spuckschutz, Isolationsspiegel, Corona Kassentisch',
    //     title: 'Corona - Infektionsschutz',
    //     article_date: '17 März 2020',
    //     text: 'Schutzkonzept aus 100% transparentem und desinfizierbarem Acrylglas gegen Tröpfcheninfektion. Direkter Kontakt mit dem Kunden wird vermieden, sodass Ihr Personal beim Kassiervorgang keinen erhöhten ' +
    //       'Infektionsrisiko ausgesetzt ist. ',
    //     link: false,
    //     buttons: true,
    //     routerLink: '/zubehoer/acryl',
    //     buttonText: 'Info'
    //   }),

    // new NewsCards(
    //   'assets/Pictures/Produkte/Neuheiten/MarkantForum2019/Markant.jpg',
    //   {
    //     title: 'MARKANT Handelsforum 2019',
    //     article_date: '17 September 2019',
    //     text:
    //       'Die Kassenzone hat die höchste Flächenproduktivität im gesamten Markt. Doch Verkaufsflächen sind begrenzt, müssen für den Kunden uneingeschränkt ' +
    //       'zugänglich sein und dürfen den Kassiervorgang nicht beeinflussen.' +
    //       ' Zusammen mit FERRERO präsentieren wir exklusiv auf dem MARKANT Handelsforum 2019 unser neues Regalierungskonzept "MULTI-TASKING".' +
    //       ' Integration von Food- und Non-Food-Sortimenten direkt unter dem Vorlaufband. Ideal in Kombination mit Ihrer Service-Kasse. ',
    //     additional_informations: '18.09.2019 - 19.09.2019, Sie finden uns auf der Sonderfläche direkt am Eingang!',
    //     newslink_1: 'assets/Pictures/Produkte/Neuheiten/MarkantForum2019/Kassenzone_markant.png',
    //     newslink_1_desc: 'Warum ist die Kassenzone wichtig?',
    //     newslink_2: 'assets/Pictures/Produkte/Neuheiten/MarkantForum2019/BeispielREWELenk.jpg',
    //     newslink_2_desc: 'Beispiel REWE Lenk, Hattingen',
    //     link: true,
    //
    //
    //   }),


    // new NewsCards(
    //   'assets/Pictures/Produkte/Neuheiten/Sagaflor.jpg',
    //   {
    //     title: 'PRO garten & tier in Kassel',
    //     article_date: '17 September 2019',
    //     text: 'Die neue Fachmesse in den Messehallen Kassel richtet sich an Fachhändler aus den Bereichen Garten und Heimtier.' +
    //       ' Entdecken Sie Sortimente und Trends zu den Themen Pflanze, Deko, ' +
    //       'Gartentechnik und -zubehör. Wir präsentieren unsere  Abwicklungskonzepte speziell für die grünen Märkte und Tierhandlungen.' +
    //       ' Wir freuen uns auf Ihren Besuch!',
    //     additional_informations: '20.09.2019 - 21.09.2019, Halle 5, Stand 566',
    //     buttons: true,
    //     buttonText: 'Weitere Informationen'
    //   }),

  ]
}
