import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-technik',
  templateUrl: './technik.component.html',
  styleUrls: ['./technik.component.css']
})
export class TechnikComponent implements OnInit {

  title = 'Die Technik';
  text = 'Eine richtungsweisende Innovation bei der Fertigung moderner\n' +
    'Kassentische ist das von uns entwickelte, selbsttragende Aluminium-\n' +
    'Spezialprofil mit eloxierter Oberfläche und entsprechenden\n' +
    'Eckverbindern. Neben der guten Ästhetik und Haltbarkeit\n' +
    'zeichnet es sich durch große Fertigungspräzision, Service-Freundlichkeit\n' +
    'und hohen Recycling-Grad aus. ';
  check_texts: string [] = [
    '100% recycelfähig',
    'Ultra stabil',
    'Ansprechendes Design'];

  Holz_Titel = 'Unser Kassentisch-Design erzeugt den besonderen Blickfang und lässt Ihr "Corporate Design" in einer ' +
    'naturgetreuen Holzoptik neu erstrahlen!';
  Checkliste_Holz: string [] = [
    'Positive Umweltbilanz',
    'Edels  und zeitloses Design',
    'Sehr gute mechanisch-technologische Eigenschaften'];

  Holz_Text = 'Holz ist der unverwüstliche Klassiker mit seinemwarmen Farbtönen und' +
    'charakteriotischen Maserungen';
  constructor() {
  }

  ngOnInit() {
  }

}
