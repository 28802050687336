import {Component, OnInit} from '@angular/core';
import {ImageProdukteService} from "../image.produkte.service";

@Component({
    selector: 'app-nonfood',
    templateUrl: './nonfood.component.html',
    styleUrls: ['./nonfood.component.css']
})
export class NonfoodComponent implements OnInit {

    constructor(public imageprodukteService: ImageProdukteService) {
    }

    ngOnInit() {
    }

    image_7273 = this.imageprodukteService.image_nonfood_7273;
    image_8485 = this.imageprodukteService.image_nonfood_8485;

}
