import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-einzelhandel',
  templateUrl: './einzelhandel.component.html',
  styleUrls: ['./einzelhandel.component.css']
})
export class EinzelhandelComponent implements OnInit {

  constructor() {



  }

  ngOnInit() {
  }
    sendEvent = () => {
        (<any>window).ga('send', 'event', {
            eventCategory: 'Kaseentischvariante',
            eventLabel: 'eventLabel',
            eventAction: 'switch',
            eventValue: 10
        });
    }


}
