import { Component, OnInit } from '@angular/core';
import {ZubehoerService} from "../zubehoer.service";

@Component({
  selector: 'app-sicherheit',
  templateUrl: './sicherheit.component.html',
  styleUrls: ['./sicherheit.component.css']
})
export class SicherheitComponent implements OnInit {

  constructor(public zubehoerService: ZubehoerService) { }

  sicherheitstechnikmodal = this.zubehoerService.zubehoerSicheheitstechnik;
  ngOnInit(): void {
  }

}
