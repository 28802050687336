import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acrylall',
  templateUrl: './acrylall.component.html',
  styleUrls: ['./acrylall.component.css']
})
export class AcrylallComponent implements OnInit {



  constructor() { }

  ngOnInit(): void {
  }

}
