import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acrylwinkel',
  templateUrl: './acrylwinkel.component.html',
  styleUrls: ['./acrylwinkel.component.css']
})
export class AcrylwinkelComponent implements OnInit {


  acrylclass :string [] = [
    'Maßgeschneiderte Lösung mit passenden Ausschnitten',
    'Scheiben werden im eigenen Haus gefertigt',
    'Hoher Schutz und ansprechendes Design',
    'Als Einzel- oder Serienlösung'
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
