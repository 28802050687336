import {Component, OnInit} from "@angular/core";


@Component({
    selector: 'app-produkte',
    templateUrl: './produkte.component.html',
    styleUrls: ['./produkte.component.css']
})
export class ProdukteComponent implements OnInit {



    constructor() {
    }

    ngOnInit() {


    }

}
