import {Injectable} from '@angular/core';

export class IMAGES {

  constructor(public image_url: string, public image_data_size: string, public image_caption: string, public img_alt: string) {
  }

}

@Injectable()
export class MediathekService {



  images_media_kassentische: IMAGES [] = [

    new IMAGES('../../../..//assets/Pictures/mediathek/Einzelkassentische/20.jpg', 'large', 'Einzelkassentisch Bild ID 1', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Einzelkassentische/22.jpg', 'large', 'Einzelkassentisch Bild ID 22', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Einzelkassentische/1.jpg', 'small', 'Einzelkassentisch Bild ID 2', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Einzelkassentische/2.jpg', 'medium', 'Einzelkassentisch Bild ID 3', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Einzelkassentische/15.jpg', 'small', 'Doppelkassentisch Bild ID 4', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Einzelkassentische/7.jpg', 'large', 'Einzelkassentisch Bild ID 5', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Einzelkassentische/13.jpg', 'large', 'Einzelkassentisch Bild ID 6', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Einzelkassentische/14.jpg', 'large', 'Einzelkassentisch Bild ID 7', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Einzelkassentische/5.jpg', 'large', 'Einzelkassentisch Bild ID 8', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Einzelkassentische/6.jpg', 'large', 'Einzelkassentisch Bild ID 9', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Einzelkassentische/8.jpg', 'large', 'Einzelkassentisch Bild ID 10', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Einzelkassentische/9.jpg', 'large', 'Einzelkassentisch Bild ID 11', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Einzelkassentische/10.jpg', 'large', 'Express-Kassentisch Bild ID 12', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Einzelkassentische/11.jpg', 'large', 'Express-Kassentisch Bild ID 13', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Einzelkassentische/12.jpg', 'large', 'Einzelkassentisch Bild ID 14', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Einzelkassentische/16.jpg', 'large', 'Einzelkassentisch Bild ID 15', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Einzelkassentische/3.jpg', 'medium', 'Einzelkassentisch Bild ID 16', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Einzelkassentische/4.jpg', 'large', 'Einzelkassentisch Bild ID 17', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Einzelkassentische/17.jpg', 'large', 'Einzelkassentisch Bild ID 18', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Einzelkassentische/18.jpg', 'large', 'Einzelkassentisch Bild ID 19', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Einzelkassentische/19.jpg', 'large', 'Einzelkassentisch Bild ID 20', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Einzelkassentische/21.jpg', 'large', 'Einzelkassentisch Bild ID 21', 'Kassentisch, Kassentische'),


  ];

  images_media_doppelkassentisch: IMAGES [] = [

    new IMAGES('../../../..//assets/Pictures/mediathek/Doppelkassentische/15.jpg', 'small', 'Doppelkassentisch Bild ID 15', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Doppelkassentische/16.jpg', 'small', 'Doppelkassentisch Bild ID 16', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Doppelkassentische/1.jpg', 'large', 'Doppelkassentisch Bild ID 1', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Doppelkassentische/2.jpg', 'large', 'Doppelkassentisch Bild ID 2', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Doppelkassentische/4.jpg', 'small', 'Doppelkassentisch Bild ID 4', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Doppelkassentische/5.jpg', 'small', 'Doppelkassentisch Bild ID 5', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Doppelkassentische/6.jpg', 'small', 'Doppelkassentisch Bild ID 6', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Doppelkassentische/7.jpg', 'small', 'Doppelkassentisch Bild ID 7', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Doppelkassentische/8.jpg', 'small', 'Doppelkassentisch Bild ID 8', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Doppelkassentische/9.jpg', 'small', 'Doppelkassentisch Bild ID 9', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Doppelkassentische/10.jpg', 'small', 'Doppelkassentisch Bild ID 10', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Doppelkassentische/11.jpg', 'small', 'Doppelkassentisch Bild ID 11', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Doppelkassentische/12.jpg', 'small', 'Doppelkassentisch Bild ID 12', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Doppelkassentische/13.jpg', 'small', 'Doppelkassentisch Bild ID 13', 'Kassentisch, Kassentische'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Doppelkassentische/14.jpg', 'small', 'Doppelkassentisch Bild ID 14', 'Kassentisch, Kassentische'),


  ];

  images_media_self_scanning: IMAGES [] = [

    new IMAGES('../../../..//assets/Pictures/mediathek/SelfScanning/1.jpg', '', 'Self-Scanning Bild ID 1', 'Kassentisch, Kassentische, Self Scanning'),
    new IMAGES('../../../..//assets/Pictures/mediathek/SelfScanning/2.jpg', '', 'Self-Scanning Bild ID 2', 'Kassentisch, Kassentische, Self Scanning'),
    new IMAGES('../../../..//assets/Pictures/mediathek/SelfScanning/3.jpg', '', 'Self-Scanning Bild ID 3', 'Kassentisch, Kassentische, Self Scanning'),
    new IMAGES('../../../..//assets/Pictures/mediathek/SelfScanning/4.jpg', '', 'Self-Scanning Bild ID 4', 'Kassentisch, Kassentische, Self Scanning')
  ];

  images_media_schranksysteme: IMAGES [] = [

    new IMAGES('../../../..//assets/Pictures/mediathek/Schranksysteme/10.jpg', '', 'Service-Kasse Bild ID 10', 'Kassentisch, Kassentische, Infokasse'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Schranksysteme/1.jpg', '', 'Service-Kasse Bild ID 1', 'Kassentisch, Kassentische, Infokasse'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Schranksysteme/2.jpg', '', 'Service-Kasse Bild ID 2', 'Kassentisch, Kassentische, Infokasse'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Schranksysteme/3.jpg', '', 'Service-Kasse Bild ID 3', 'Kassentisch, Kassentische, Infokasse'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Schranksysteme/4.jpg', '', 'Service-Kasse Bild ID 4', 'Kassentisch, Kassentische, Infokasse'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Schranksysteme/5.jpg', '', 'Service-Kasse Bild ID 5', 'Kassentisch, Kassentische, Infokasse'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Schranksysteme/6.jpg', '', 'Service-Kasse Bild ID 6', 'Kassentisch, Kassentische, Infokasse'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Schranksysteme/7.jpg', '', 'Service-Kasse Bild ID 7', 'Kassentisch, Kassentische, Infokasse'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Schranksysteme/8.jpg', '', 'Service-Kasse Bild ID 8', 'Kassentisch, Kassentische, Infokasse'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Schranksysteme/9.jpg', '', 'Service-Kasse Bild ID 9', 'Kassentisch, Kassentische, Infokasse'),

  ];

  images_media_getraenkemarkt: IMAGES [] = [

    new IMAGES('../../../..//assets/Pictures/mediathek/Getraenkemaerkte/1.jpg', '', 'Getraenktemarkt ID Bild 1', 'Kassentisch, Kassentische, Getränkemärkte'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Getraenkemaerkte/2.jpg', '', 'Getraenktemarkt ID Bild 2', 'Kassentisch, Kassentische, Getränkemärkte'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Getraenkemaerkte/3.jpg', '', 'Getraenktemarkt ID Bild 3', 'Kassentisch, Kassentische, Getränkemärkte'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Getraenkemaerkte/4.jpg', '', 'Getraenktemarkt ID Bild 4', 'Kassentisch, Kassentische, Getränkemärkte'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Getraenkemaerkte/5.jpg', '', 'Getraenktemarkt ID Bild 5', 'Kassentisch, Kassentische, Getränkemärkte'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Getraenkemaerkte/6.jpg', '', 'Getraenktemarkt ID Bild 6', 'Kassentisch, Kassentische, Getränkemärkte'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Getraenkemaerkte/7.jpg', '', 'Getraenktemarkt ID Bild 7', 'Kassentisch, Kassentische, Getränkemärkte'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Getraenkemaerkte/8.jpg', '', 'Getraenktemarkt ID Bild 8', 'Kassentisch, Kassentische, Getränkemärkte'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Getraenkemaerkte/9.jpg', '', 'Getraenktemarkt ID Bild 9', 'Kassentisch, Kassentische, Getränkemärkte'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Getraenkemaerkte/10.jpg', '', 'Getraenktemarkt ID Bild 10', 'Kassentisch, Kassentische, Getränkemärkte'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Getraenkemaerkte/11.jpg', '', 'Getraenktemarkt ID Bild 11', 'Kassentisch, Kassentische, Getränkemärkte'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Getraenkemaerkte/12.jpg', '', 'Getraenktemarkt ID Bild 12', 'Kassentisch, Kassentische, Getränkemärkte'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Getraenkemaerkte/13.jpg', '', 'Getraenktemarkt ID Bild 13', 'Kassentisch, Kassentische, Getränkemärkte'),

  ];

  images_media_theke: IMAGES [] = [

    new IMAGES('../../../..//assets/Pictures/mediathek/Theken/1.jpg', '', 'Service-Theken ID Bild 1', 'Kassentisch, Kassentische, Theke'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Theken/2.jpg', '', 'Service-Theken ID Bild 2', 'Kassentisch, Kassentische, Theke'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Theken/3.jpg', '', 'Service-Theken ID Bild 3', 'Kassentisch, Kassentische, Theke'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Theken/5.jpg', '', 'Service-Theken ID Bild 4', 'Kassentisch, Kassentische, Theke'),
    new IMAGES('../../../..//assets/Pictures/mediathek/Theken/6.jpg', '', 'Service-Theken ID Bild 5', 'Kassentisch, Kassentische, Theke'),


  ]
}

