export class Cards {

  constructor(
    public card?: {
      title?: string,
      img_url?: string,
      desc?: string,
      button?: string,
      link?: string

    }
  ) {
  }
}

export class Planer {

  constructor(
    public title_planer?: string,
    public img_planer?: string,
    public text_planer?: string,
    public href_planer?: string) {
  }

}

export class PlanerModal {

  constructor(public img_planer_modal, public title_planer_modal, public text_planer_modal) {
  }
}

export class CustomerModal {

  constructor(public heading?: string, public text?: string, public url?: string) {
  }

}

export class Projectgallery {

  constructor(public title?: string, public img_src?: string) {
  }
}
