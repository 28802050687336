import {Component, OnInit} from '@angular/core';
import {NgForm} from "@angular/forms";
import {HttpClient} from '@angular/common/http';

export interface FormModel {
  captcha?: string;
}



@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  endpoint = 'https://us-central1-potrafke-23f6a.cloudfunctions.net/httpEmail';

  public formModel: FormModel = {};

  title = 'Kontakt';
  constructor(private http: HttpClient) {
  }

  ngOnInit() {
  }

  onSubmit(form: NgForm) {

    console.log(form);

  }


}


