import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';



@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {






    select = 'Select Language';
    DE = 'de';
    EN = 'en';
    FR = 'fr';
    RU = 'ru';
    German = 'German';
    English = 'English';
    France = 'France';
    Russia = 'Russia';


    constructor(public translate: TranslateService) {
        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('de');
        translate.addLangs(['English', 'Russia']);
    }

// the lang to use, if the lang isn't available, it will use the current loader to get them
    switchLanguage(language: string) {
        this.translate.use(language);
    }



        ngOnInit() {
    }


}
