import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acryl',
  templateUrl: './acryl.component.html',
  styleUrls: ['./acryl.component.css']
})
export class AcrylComponent implements OnInit {

  acrylclass :string [] = [
    'Maßgeschneiderte Lösung mit passenden Ausschnitten',
    'Scheiben werden im eigenen Haus gefertigt',
    'Hoher Schutz und ansprechendes Design',
    'Als Einzel- oder Serienlösung'
  ];


  constructor() { }

  ngOnInit(): void {
  }

}
