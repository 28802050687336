import {Component, OnInit} from '@angular/core';


@Component({
  selector: 'app-zubehoer',
  templateUrl: './zubehoer.component.html',
  styleUrls: ['./zubehoer.component.css']
})
export class ZubehoerComponent implements OnInit {

  pdfSrc = '/assets/download/Katalog/Katalog_04.06.2021.pdf';

  page = 1;
  totalPages: number;
  isLoaded = false;

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }

  constructor() {
  }


  ngOnInit() {
  }

}
