import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cashcarry',
  templateUrl: './cashcarry.component.html',
  styleUrls: ['./cashcarry.component.css']
})
export class CashcarryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
